<template>
  <v-form ref="formElem" @submit.prevent="() => {}">
      <!-- Dialog Content -->
      <v-card>
        <v-card-title>Confirm Appointment</v-card-title>
        <v-card-text>
          <p><b>Appointment Number:</b> {{  appointment.current_number }} </p>

          <p v-if="participant"><b>Participant:</b> {{ participant.last_name }} {{ participant.first_name }}</p>

          <p v-if="participant"><b>Contact:</b> {{ participant.phone }}, {{ participant.email }}</p>
          
          <p><b>Service:</b> {{  appointment.rechargeable.name.en }} </p>
          <p><b>Location:</b>{{ t(destination.name ||  destination.address) }}</p>
          <p v-if="confirmedApplications.length">
            <b>Previously Confirmed: </b>
            <template v-for="app in confirmedApplications" v-if="app.inventory_snapshot && app.inventory_snapshot.info">
              {{ app.inventory_snapshot.info.started_at | date }}
            </template>
          </p>
          <p><b>Selected Date & Time:</b></p>
          <v-radio-group v-model="selectedApplication" :rules="[required]">
            <v-radio
              v-for="app in confirmableApplications"
              v-if="app.inventory_snapshot && app.inventory_snapshot.info"
              :key="app.uuid"
              :label="app.inventory_snapshot.info.started_at | date"
              :value="app.uuid"
            />
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            type="button"
            class="mr-4"
            color="success"
            :loading="loading"
            :disabled="loading"
            @click="confirmAppointment"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-form>
</template>

<script>
import AppointmentAPI from '@/api/appointment'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'
import moment from 'moment'

export default {
  filters: {
    date: value => {
      if (!value) return

      return moment(value).format('YYYY-MM-DD HH:mm')
    },
  },
  props: {
    appointment: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const formElem = ref(null)
    const selectedApplication = ref(null)

    const toggleDialog = status => {
      isDialogVisible.value = status
    }

    const confirmableApplications = computed(() => {
      return props.appointment.applications.filter(application => application.status === 'pending')
    })

    const confirmedApplications = computed(() => {
      return props.appointment.applications.filter(application => application.status === 'accepted')
    })

    const confirmAppointment = async () => {
      if (!formElem.value.validate()) return

      loading.value = true

      try {
        await AppointmentAPI.confirm(selectedApplication.value)

        toggleDialog(false)
        reset()

        emit('confirmed')
      } catch (err) {
        console.log(err)

        alert('Something went wrong!')
      }
    }

    const reset = () => {
      loading.value = false
      selectedApplication.value = null
    }

    const destination = computed(() => props.appointment.applications?.[0]?.destination)

    const participant = computed(() => props.appointment.group?.participant)

    return {
      t,
      loading,
      isDialogVisible,
      destination,
      participant,

      toggleDialog,

      selectedApplication,
      confirmableApplications,
      confirmedApplications,
      confirmAppointment,

      formElem,
      required,
    }
  },
}
</script>

