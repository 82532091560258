<template>
    <v-card>
      <v-card-title class="font-weight-semibold">
        Cancel Appointment
      </v-card-title>
      <v-card-text>
        <p>Are you sure you want to cancel this appointment?</p>

        <p><b>Appointment Number:</b> {{ appointment.current_number }}</p>

        <p v-if="participant"><b>Participant:</b> {{ participant.last_name }} {{ participant.first_name }}</p>

        <p v-if="participant"><b>Contact:</b> {{ participant.phone }}, {{ participant.email }}</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          type="button"
          class="mr-4"
          color="success"
          :loading="loading"
          :disabled="loading"
          @click="cancelAppointment"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import AppointmentAPI from '@/api/appointment'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    appointment: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const cancelAppointment = async () => {
      loading.value = true

      try {
        const { applications } = props.appointment
        const app = applications.find(a => a.status === 'confirmed') || applications.find(a => a.status === 'pending') || applications[0]
        await AppointmentAPI.cancel(app.uuid)

        reset()

        toggleDialog(false)

        emit('cancelled')
      } catch (err) {
        console.log(err)

        alert('Something went wrong!')
      }
    }

    const toggleDialog = status => {
      isDialogVisible.value = status
    }

    const reset = () => {
      loading.value = false
    }

    const participant = computed(() => props.appointment.group.participant)

    return {
      loading,
      isDialogVisible,

      toggleDialog,
      cancelAppointment,
      participant,
    }
  },
}
</script>
