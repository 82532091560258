<template>
  <v-card>
    <ListFilter @statusChanged="onStatusChanged" @search="onSearching" />
    <ListTable :appointments="appointments" :total-appointments="totalAppointments" :loading="loading" @refresh="fetchAppointments" @optionChanged="onOptionChanged" />
  </v-card>
</template>

<script>
import AppointmentAPI from '@/api/appointment'
import { withOptions } from '@/assets/js/utils'
import { ref } from '@vue/composition-api'
import ListFilter from './ListFilter'
import ListTable from './ListTable'
import useFilter from './useFilter'
import useTable from './useTable'

export default {
  components: {
    ListFilter,
    ListTable,
  },
  setup() {
    const loading = ref(false)

    const { defaultStatus } = useFilter()
    const { tableOptions } = useTable()

    const queryParams = ref({
      status_in: defaultStatus,
    })

    const appointments = ref([])
    const totalAppointments = ref(0)

    const fetchAppointments = async () => {
      loading.value = true

      const { records } = await AppointmentAPI.getAll(withOptions(tableOptions.value, queryParams.value))

      appointments.value = records.filter(record => !['available', 'depleted', 'expired'].includes(record.status))
      totalAppointments.value = appointments.value.length

      loading.value = false
    }

    const onOptionChanged = options => {
      tableOptions.value = options

      fetchAppointments()
    }

    const onSearching = searchQuery => {
      queryParams.value.search = searchQuery

      fetchAppointments()
    }

    const onStatusChanged = status => {
      queryParams.value.status_in = status

      fetchAppointments()
    }

    return {
      loading,

      defaultStatus,

      appointments,
      totalAppointments,

      onSearching,
      onStatusChanged,
      onOptionChanged,

      fetchAppointments,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
