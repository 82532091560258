<template>
    <v-card>
      <v-card-title class="font-weight-semibold">
        Check In Appointment
      </v-card-title>
      <v-card-text>
        <p>Are you sure you want to check in this appointment?</p>

        <p><b>Check in will <u>complete this appointment</u>.</b> Make sure the customer is now at your location.</p>

        <p><b>Appointment Number:</b> {{  appointment.current_number }}</p>

        <p v-if="participant"><b>Participant:</b> {{ participant.last_name }} {{ participant.first_name }}</p>

        <p v-if="participant"><b>Contact:</b> {{ participant.phone }}, {{ participant.email }}</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          type="button"
          class="mr-4"
          color="success"
          :loading="loading"
          :disabled="loading"
          @click="completeAppointment"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import AppointmentAPI from '@/api/appointment'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    appointment: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const completeAppointment = async () => {
      loading.value = true

      try {
        await AppointmentAPI.complete(props.appointment.applications[0].uuid, {
          code: props.appointment.qr_codes[0]?.code,
        })

        reset()

        toggleDialog(false)

        emit('checkedIn')
      } catch (err) {
        console.log(err)

        alert('Something went wrong!')
      }
    }

    const toggleDialog = status => {
      isDialogVisible.value = status
    }

    const reset = () => {
      loading.value = false
    }

    const participant = computed(() => props.appointment.group.participant)

    return {
      loading,
      isDialogVisible,

      toggleDialog,
      completeAppointment,
      participant,
    }
  },
}
</script>
